#projects {
  padding: 3rem 6rem 0;

}

.app__projects-blogcard img {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
  }
  
  .app__projects-blogcard {
    display: flex !important;
    flex-direction: column; 
    align-items: center; 
    justify-content: center; 
    color: var(--color-white);
    margin: 40px 0 0;
  }
  
  .app__projects-text {
    margin: 20px;
  }
  
  .app__projects-project-title {
    font-size: 2rem; 
    margin-bottom: 1rem;
  }
  
  .app__projects-description {
    line-height: 1.6;
    width: 100%;
    max-width: 700px;
    font-size: 1rem; 
    color: var(--color-white);
  }
  
  .app__projects-stack {
    display: flex;
    gap: 20px;
    align-items: baseline;
    justify-content: center; 
  }
  
  .app__projects-tag-icon {
    margin-right: 20px;
    height: 1rem;
    width: 1rem;
  }
  
  .app__projects h3 {
    margin: 20px 0;
  }
  
  .app__projects-tag {
    text-align: center;
    margin-top: 20px;
  }
  
  .app__projects-links {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    margin-top: 10px;
  }
  
  .app__projects-links li a:hover {
    color: var(--color-grey);
  }
  
  .app__projects-links li {
    list-style-type: none;
    margin: 5px 0; 
  }
  
  @media (min-width: 768px) {
    .app__projects-blogcard {
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }
  
    .app__projects-links {
      flex-direction: row;
    }
  
    .app__projects-links li {
      margin-right: 10px;
    }
  }
  
  @media (max-width: 767px) {
    .app__projects-project-title {
      font-size: 1.5rem;
    }
  
    .app__projects-description {
      font-size: 0.875rem;
    }
  
    .app__projects-tag-icon {
      height: 0.875rem;
      width: 0.875rem;
    }
  }
  
  @media (max-width: 480px) {
    .app__projects-project-title {
      font-size: 1.25rem;
    }
  
    .app__projects-description {
      font-size: 0.75rem;
    }
  
    .app__projects-tag-icon {
      height: 0.75rem;
      width: 0.75rem;
    }
  
    .app__projects-blogcard {
      margin: 20px 0;
    }
  }
  