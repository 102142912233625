.app__technologies-p {
    width: 100%;
    max-width: 700px;
    line-height: 25px;
  }
  
  .app__technologies-list {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin: 3rem 0;
    padding: 0 20px;
  }
  
  .app__technologies-list li {
    width: 100%;
    max-width: 350px;
  }
  
  .app__technologies-list li p {
    font-size: 14px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.75);
  }
  
  .app__technologies-list li h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.02em;
  }
  
  /* Media Queries */
  
  @media (max-width: 599px) {
    .app__technologies-list {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      text-align: center;
    }
  
    .app__technologies-p {
      line-height: 20px;
    }
  
    .app__technologies-list li p {
      font-size: 12px;
      line-height: 18px;
    }
  
    .app__technologies-list li h3 {
      font-size: 14px;
    }
  }
  
  @media (min-width: 600px) and (max-width: 899px) {
    .app__technologies-list {
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
    }
  }
  
  @media (min-width: 900px) {
    .app__technologies-list {
      grid-template-columns: repeat(3, 1fr);
      gap: 40px;
    }
  
    .app__technologies-p {
      line-height: 28px;
    }
  
    .app__technologies-list li p {
      font-size: 16px;
      line-height: 24px;
    }
  
    .app__technologies-list li h3 {
      font-size: 18px;
    }
  }
  