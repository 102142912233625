.app__about-p {
  width: 100%;
  max-width: 700px;
  line-height: 25px;
  margin-bottom: 50px;
}

.timeline {
  position: relative;
  padding: 20px 0;
  margin: 20px 0;
}

.timeline::before {
  content: '';
  position: absolute;
  left: 20px; 
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #ccc;
}

.timeline-item {
  position: relative;
  margin-bottom: 40px;
  padding-left: 80px; 
  opacity: 0; 
  transform: translateY(50px); 
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.timeline-item::before {
  content: '';
  position: absolute;
  left: 16px; 
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
}

.timeline-year {
  font-weight: bold;
  margin-right: 10px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.75);

}

.timeline-text {
  margin: 5px 0px;
  font-size: 18px;
}
