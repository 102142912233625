.app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem;
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.app__navbar-logo img {
    width: 150px;
}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.app__navbar-links li {
    margin: 0 3rem;
}

.app__navbar-links li:hover {
    color: var(--color-grey);
}

.app__navbar-socials-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;
    list-style: none;

}
.app__navbar-socials-links li  {
    &:hover {
        transform: scale(1.2);
        cursor: pointer;
        
      }

}
.app__navbar-socials-links a {
    margin: 0 1rem;
    text-decoration: none;
    transition: .5s ease;

    
}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transition: 0.5s ease;
    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: #fff;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}

.flex__center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__navbar-smallscreen-links {
    list-style: none;
}

.app__navbar-smallscreen-links li {
    margin: 2rem;
    cursor: pointer;
    text-align: center;
    font-size: 2rem;
}

.app__navbar-smallscreen-links li:hover {
    color: var(--color-grey);
}

.smallscreen_socials-links {
    /* flex-direction: column; */
    margin: 2rem;
}

.smallscreen_socials-links li{
    font-size: 2rem;
}
@media screen and (min-width: 2000px) {
    .app__navbar-logo img {
        width: 210px;
    }
}
@media screen and (max-width:1150px) {
    .app__navbar-links {
        display: none;
    }
.bigscreem_social-links {
    display: none;
}
    .app__navbar-smallscreen {
        display: flex;
    }

}

@media screen and (max-width: 650px) {
    .app__navbar {
        padding: 1rem;
    }

    .app__navbar-logo img {
        width: 110px;
    }
}