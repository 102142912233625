.p__opensans {
    font-family: var(--font-alt);
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 28px;
    font-size: 16px;
  }

  .section__padding {
    padding: 3rem 6rem;
  }
  body, html {
    height: 100%;
    margin: 0;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content {
    flex: 1;
  }
  
  @media screen and (max-width: 650px) {
    .section__padding {
      padding: 2rem 2rem !important;
    }
  }