.app__technologies-p span {
  font-weight: bolder;
}
.app__technologies-p {
  margin-bottom: 2rem;
}
.contact-form {
    max-width: 700px;
    padding: 1em 0;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-field {
    margin-bottom: 1em;
  }
  
  .form-field label {
    display: block;
    margin-bottom: 1em;
    color: rgba(255, 255, 255, 0.75);
  }
  
  .form-field input,
  .form-field textarea {
    width: 100%;
    padding: 0.8em;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
    color: white;
  }

  .form-field textarea {
    height: 200px;
  }
  
  .form-field input:focus,
  .form-field textarea:focus {
    border-color: rgba(255, 255, 255, 0.75);
    outline: none;
  }
  
  .submit-button {
    padding: 1rem;
    margin-top: 2rem;
    border-color: white;
    outline: none;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    border: 1px solid #ccc;
    text-transform: uppercase;
    background-color: transparent;
    transition: transform 0.2s ease-in-out;
  }
  
.submit-button:hover {
  transform: scale(1.1);
  }
  
  .form-message {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    color: white;
  }
  
  @media (max-width: 600px) {
    .contact-form {
      padding: 0.5em;
    }
  
    .form-field label {
      font-size: 0.9em;
    }
  
    .form-field input,
    .form-field textarea {
      font-size: 0.9em;
    }
  
    .submit-button {
      padding: 0.6em 1.2em;
      font-size: 0.9em;
    }
  }
  