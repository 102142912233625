@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
:root {
    font-family: 'Raleway', sans-serif;

    --color-golden: #DCCA87;
    --color-black: #0C0C0C;
    --color-gray: #545454;
    --color-crimson: #F5EFDB;
    --color-grey: #AAAAAA;
    --color-white: #FFFFFF;
}

*
 {
    background-color: #0E1624;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
  }
  a {
    color: unset;
    text-decoration: none;
  }

  .app__title{
    font-size: 50px;
    margin-top: 1rem;
    color: var(--color-white);
    margin-bottom: 1rem;

}
.app__container {
    color: var(--color-white);
}

.app__section_divider {
  width: 64px;
  height: 6px;
  border-radius: 10px;
  background-color: var(--color-white);

}