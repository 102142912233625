/* General styles */
:root {
    --color-white: #fff;
  }
  
  .app__hearder {
    color: var(--color-white);
    padding: 2rem;
  }
  
  .app__header-h1 {
    font-size: 2.5rem; /* 40px */
    margin-bottom: 2rem;
    letter-spacing: 0.04em;
    line-height: 1.25; /* 50px */
  }
  
  .app__header-p {
    line-height: 1.5; /* 25px */
    width: 100%;
    max-width: 700px;
    font-size: 1rem; /* 16px */
  }
  
  .app__header-button {
    padding: 1rem;
    margin-top: 2rem;
    border-color: white;
    outline: none;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    border: 1px solid #ccc;
    text-transform: uppercase;
    background-color: transparent; /* Added background for better visibility */
    transition: transform 0.2s ease-in-out;
  
    &:hover {
      transform: scale(1.1);
    }
  }
  
  /* Media Queries */
  @media screen and (max-width: 1150px) {
    .app__header-h1 {
      font-size: 2rem; /* 30px */
    }
  }
  
  @media screen and (max-width: 650px) {
    .app__header-h1 {
      font-size: 1.25rem; /* 20px */
      line-height: 1.5; /* 30px */
    }
  
    .app__header-p {
      width: 90%;
      font-size: 0.875rem; /* 14px */
    }
  
    .app__header-button {
      padding: 0.75rem;
      font-size: 0.875rem; /* 14px */
    }
  }
  
  /* Small devices (phones, 600px and down) */
  @media screen and (max-width: 600px) {
    .app__hearder {
      padding: 1rem;
    }
  
    .app__header-h1 {
      font-size: 1.5rem;
    }
  
    .app__header-p {
      font-size: 0.9rem;
    }
  
    .app__header-button {
      padding: 0.5rem 1rem;
    }
  }
  